Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "rolesandpermissions2";
exports.labelBodyText = "rolesandpermissions2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.title = "Choose your role";

exports.apiContentType = "application/json";
exports.apiMethodType = "GET";
exports.getUserDetailsEndPoint = "bx_block_profile_bio/user_deatils";
// Customizable Area End